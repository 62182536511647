/**
 * Built using Imarc Boilerplate v2.1
 *
 * Copyright 2016 Imarc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * = Table of Contents =
 * Variables
 * Imports
 * Base
 * Layout
 * Typography
 * Forms
 * Messaging
 * Tables
 * Calendar
 * CMS
 * Page Specific
 * Media Queries
 */


/* = Variables = */
$font-stack: "Open Sans", Arial, sans-serif;

/* Grays */
$white:     #FFF;
$matte:     #F5F5F5;
$lightgray: #f0f0f0;
$gray:      #545454;
$darkgray:  #343434;
$charcoal:  #282828;
$navy:      #25333e;
$black:     #000;

/* The brand colors (name them the color) */
$brand-blue: #6183b1;
$brand-blue-dark: #3e5f8a;
$brand-blue-medium: #456389;
$brand-blue-light: #bbebff;
$brand-green: #919c36;
$brand-green-dark: #849444;
$brand-sand: #b8b69d;
$brand-teal: #8e9eae;

/* Messaging colors */
$success: #429c3e;
$error: #ab2029;
$info: #DDD;

/* Applied colors */
$plain-text: $darkgray;
$meta-text: lighten($charcoal, 35%);
$link: $brand-blue;
$link-hover: $brand-blue-dark;
$border: lighten($charcoal, 70%);

/* Social Network Colors */
$facebook: #3664A2;
$twitter: #55ACEE;
$linkedin: #0077B5;
$youtube: #CD201F;

/* Breakpoint values */
$medium: 669px;
$large: 1024px;
$xl: 1200px;

@use 'sass:color';
@import "_mixins";
@import "_components";
@import "_print";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');


/* = Base = */
a, abbr, address, article, aside, audio, b, blockquote, body, button, canvas, caption, cite, code, datalist, dd, div, dl, dt, em, embed, fieldset, figcaption, figure, blog, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, hr, i, iframe, img, input, label, legend, li, main, meter, nav, object, ol, option, p, pre, progress, q, section, select, span, strong, sub, sup, table, tbody, td, textarea, tfoot, th, thead, tr, u, ul, video {
    border: 0;
    box-sizing: border-box;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, video {
    display: block;
}

html {
    background-color: #FFF;
    color: $plain-text;
    font-family: $font-stack;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    min-width: 300px;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

iframe {
    max-width: 100%;
}

.group {
    @include grouping;
}

.sr-only {
    @include visuallyhidden;
}

::selection {
    background-color: $brand-green;
    color: $charcoal;
}

::-moz-selection {
    background-color: darken($brand-green, 30%);
    color: #FFF;
}


/* = Layout = */
.search-drawer {
    background-color: $darkgray;
    transform: translateY(1rem);
    > div {
        max-height: 0;
        overflow: hidden;
        transition: max-height 150ms ease;
        &.open {
            max-height: 150px;
        }
    }
}


header.primary {
    background-color: $white;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    padding: 1rem 0;
    position: relative;
    z-index: 8000;

    .branding {
        flex-basis: 170px;
        flex-shrink: 0;
    }
}

nav {
    margin-bottom: 2rem;
    ul {
        @include no-bullets;

        display: flex;
        li {
            margin-bottom: 0;
        }
    }
    a {
        color: $link;
        display: block;
        padding: 0 0.5rem;
    }

    &.breadcrumbs {
        @include breadcrumbs();
    }

}

nav.primary {
    font-weight: 400;
    margin-bottom: 0.7rem;
    > ul {
        li {
            margin-bottom: 0;
        }
        > li {
            padding: 0.75rem 1.75rem 1rem;
            transition: background-color 300ms;
        }
        > li > a {
            padding: 0;
        }
    }
    li a {
        color: $plain-text;
    }
    li.donate {
        padding: 0;
    }
    li.donate a {
        color: #FFF;
        padding: 0.6rem 2rem;
        &:hover {
            color: #FFF;
        }
    }
    li.active,
    li:hover {
        background-color: $brand-blue;
        a {
            color: #FFF;
        }
    }
    .mobile & {
        > ul > li {
            font-weight: 400;
            padding: 0;
        }
        a {
            display: block;
            padding: 0.75rem 0;
        }
        li.active,
        li:hover {
            background-color: transparent;
        }
        li.active {
            background-color: transparent;
            border-bottom: 3px solid $link;
            a {
                color: $link;
            }
        }
        li.donate a {
            background-color: transparent;
            font-weight: 400;
            padding-bottom: 0.75rem;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0.75rem;
        }
        li a {
            color: #FFF;
        }
    }
}

nav.utility {
    margin-bottom: 0.5rem;
    a {
        color: #7D7D7D;
        font-size: 0.8rem;
    }
    a:hover,
    a:focus {
        color: $plain-text;
    }
    .mobile & {
        a:hover,
        a:focus {
            color: $brand-green;
        }
        ul {
            display: flex;
            flex-flow: row wrap;
        }
        ul > li {
            flex: 0 0 50%;
        }
    }
}

.desktop nav.primary {
    @include dropdown($background: $brand-blue, $color: #FFFFFF);
    li.mega {
        @include dropdown-mega();
        .dropdown {
            top: calc(100% - 1rem);
        }
    }
}

.mobile {
    > div.wrapper {
        display: flex;
        flex-flow: column-reverse nowrap;
    }
    .submenu ul li a {
        padding: 0.75rem 0;
    }
}

button.sub-menu-toggle {
    padding: 0.1rem 0.85rem;
    .fa {
        font-size: 1.25rem;
        position: relative;
    }
    .submenu li:first-child & {
        margin-bottom: 1.5rem;
        padding-bottom: 0.75rem;
        padding-top: 0.65rem;
        .fa {
            margin-right: 0.25rem;
            top: 0;
        }
    }
}

.header-content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .menu-toggle {
        padding: 0.75rem 1.25rem 0.7rem;
    }
    .menu-toggle:hover,
    .menu-toggle:focus {
        background-color: $link;
    }
    > .submenu {
        z-index: 9001;
        &.sub-menu {
            z-index: 9002;
        }
    }
}

.desktop {
    display: none;
}

form.search {
    display: flex;
    .text,
    .submit {
        margin-bottom: 0;
    }
    .text {
        width: 100%;
    }
    label {
        @include visuallyhidden;
    }
    .submit {
        flex-basis: 33%;
        flex-shrink: 0;
    }
    input {
        border: none;
        width: 100%;
    }
    input[type="submit"] {
        padding: 1rem 2rem;
    }
}

///
/// = Alert Banner =
///
.alert-banner {
    color: $white;
    padding: 1em 0;
    text-align: center;
    &.notice {
        background: $brand-blue;
    }
    &.alert {
        background: $error;

        a:hover {
            color: $info;
        }
    }
    a {
        color: $white;
        font-weight: 600;
    }
    p {
        display: inline-block;
        margin-bottom: .5rem;
        &:last-of-type {
            margin: 0;
        }
    }
}

.hero {
    @include screen($alpha: 0.7, $background: transparent);
    padding: 3rem 0;
    position: relative;
    text-align: center;
    .img {
        background-image: url('/assets/images/sar-9488-adjust.jpg');
    }
    section {
        @include grid($justify: center);
        flex-direction: column;
    }
    section > * {
        flex: 0 0 auto;
    }
    h1 {
        font-weight: 800;
        margin-bottom: 0.15em;
        line-height: .9;
        text-transform: uppercase;
        text-shadow: 0 0 10px rgba(0,0,0,.3);
    }
    h4 {
        margin-top: 1.5rem;
        text-transform: uppercase;
        text-shadow: 0 0 10px rgba(0,0,0,.3);
    }
    .copy {
        * {
            color: #FFF;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }
    .subhead {
        color: #FFF;
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 0.25rem;
    }
    @media only screen and (min-width: $large) {
        padding: 6rem 0;
        h1 {
            font-size: 4rem;
        }
        .copy {
            margin-left: 0;
            margin-right: 0;
            text-align: left;
        }
        .push {
            @include grid($align: flex-end, $justify: center);
        }
        .pull {
            @include grid($align: flex-start, $justify: center);
        }
        .push,
        .pull {
            flex-direction: column;
            .copy {
                flex: 0 0 auto;
                width: 50%;
            }
        }
    }
}

.mold {
    @include grouping;
    .frame {
        @include container;
        padding-left: 0;
        padding-right: 0;
    }
}

.torso {
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.breadcrumbs {
    @include breadcrumbs();
}

.conditions {
    background-color: $brand-blue;
    color: #FFF;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
    text-align: center;

    a.about {
        color: darken($link, 22%);
        display: inline-block;
        font-size: 0.65rem;
        position: relative;
        transform: translateY(0.75rem);
        &::after {
            background-color: darken($link, 22%);
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            top: 100%;
            width: 100%;
        }
    }
    a.about:hover {
        color: $brand-blue-light;
        &::after {
            background-color: $brand-blue-light;
        }
    }
    .wrapper {
        @include grid($justify: flex-start);
        flex-flow: row wrap;
        > * {
            flex: 0 1 50%;
        }
        div:last-child {
            text-align: center;
        }
        dl,
        dt,
        dd {
            font-weight: 300;
            margin: 0;
            padding: 0;
            text-align: center;
        }
        dt {
            color: $brand-blue-light;
            font-size: 0.9rem;
        }
        dd {
            font-size: 1.15rem;
            line-height: 0.9;
            margin-bottom: 1rem;
        }
    }
    @media only screen and (min-width: $medium) {
        padding-bottom: 1.75rem;
        padding-top: 1.75rem;
        .wrapper {
            > * {
                flex: 0 1 auto;
            }
            div {
                margin-right: 2rem;
            }
            div:last-child {
                margin-left: auto;
                margin-right: 0;
                text-align: left;
                a.button {
                    @include button($background: #FFF, $color: $plain-text);
                    float: right;
                }
            }
            dd {
                font-size: 1.75rem;
            }
        }
    }
    @media only screen and (min-width: $large) {
        text-align: left;
        a.about {
            font-size: 0.75rem;
        }
        .wrapper {
            flex-flow: row nowrap;
            div {
                margin-right: 6rem;
            }
            div:last-child {
                a.button {
                    padding-bottom: 0.85rem;
                    padding-top: 0.85rem;
                }
            }
            dd {
                font-size: 2.55rem;
                margin-bottom: 0;
            }
        }
    }
}

section.sector {
    h2 {
        font-weight: 300;
        margin-bottom: 2rem;
        text-align: center;
    }
    @media only screen and (min-width: $large) {
        h2 {
            margin-bottom: 4rem;
        }
    }
}

.personas {
    padding-bottom: 2rem;
    padding-top: 2rem;
    .tiles {
        > div {
            background-color: transparent;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 0;
        }
        div a {
            background-color: rgba($brand-green, 0.75);
            color: #FFF;
            display: block;
            font-size: 0.9rem;
            padding: 1.5rem 1.75rem 0.75rem;
            transition: box-shadow 300ms, background-color 300ms;
            &:hover {
                background-color: rgba(darken($brand-green, 6%), 1);
                box-shadow: inset 0 0 0 8px darken($brand-green, 10%);
            }
            &::after {
                @include fontawesome($character: '\f105');
                display: block;
                font-size: 1.8rem;
                text-align: right;
            }
            h3 {
                color: #393e0a;
                font-size: 1.15rem;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
    @media only screen and (min-width: $medium) {
        .tiles {
            @include tiles($gutter: 2.5rem, $columns: 3);
            > div {
                padding-top: 200px;
            }
        }
    }

    @media only screen and (min-width: $large) {
        padding-bottom: 5rem;
        padding-top: 5em;
        .tiles {
            @include tiles($gutter: 5rem, $columns: 3);

            > div {
                padding-top: 250px;
            }
            div a {
                background-color: rgba($brand-green, 1);
                transform: translate3d(30px, 90px, 0);
                &::after {
                    font-size: 3.25rem;
                }
            }
        }
    }
}

.newsletter {
    background-color: #F3F3F3;
    padding-bottom: 2rem;
    padding-top: 2rem;
    text-align: center;
    h4 {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 0.2rem;
    }
    form {
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        .text {
            @include grid($align: stretch);
            margin-bottom: 0;

            > div {
                display: flex;
            }
        }

        .checkboxes .newsletter-type {
            font-size: 1rem;
        }

        .text button {
            flex: 0 0 auto;
        }
        input[type="text"] {
            border: none;
            flex-grow: 1;
            font-size: 1rem;
        }
    }
    @media only screen and (min-width: $large) {
        padding-bottom: 5rem;
        padding-top: 5rem;
        text-align: left;
        h4 {
            font-size: 2.2rem;
        }
        p {
            margin-bottom: 0;
        }
        .wrapper {
            @include grid();
            > div {
                flex: 0 1 45%;
            }
        }
        form {
            box-shadow: 0 0 20px rgba(0,0,0,0.1);
        }

    }
}

.bloggy {
    .copy h2 {
        margin-bottom: 0.4rem;
        b, em {
            color: $brand-blue-light;
        }
        b {
            display: block;
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 0.3rem;
        }
        em {
            font-style: normal;
            text-transform: uppercase;
        }
    }

    @media only screen and (min-width: $large) {
        .copy {
            transform: translate(-3rem);
            h2 {
                padding-right: 3.5rem;
            }
        }
    }

    .author {
        margin-bottom: 2rem;

        @media only screen and (min-width: $large) {
            img {
                position: absolute;
                right: 0;
                top: 2rem;
                transform: translateX(50%);
                border: 7px solid rgba(darken($brand-blue-dark, 20%), .6);
                max-width: 150px;
            }
        }
    }
}

.mission {
    padding-bottom: 2rem;
    padding-top: 2rem;
    .tiles {
        @include tiles($gutter: 4rem, $columns: 3);
        flex-wrap: nowrap;
        > * {
            background-color: $brand-blue;
            border-left: 6px solid #FFF;
            border-right: 6px solid #FFF;
            color: #FFF;
            margin-bottom: 2rem;
            padding-left: 2rem;
            padding-right: 2.5rem;
            padding-top: 0;
            text-align: center;
        }
        h3 {
            background-color: $brand-blue-dark;
            color: $brand-blue-light;
            display: inline-block;
            font-size: 1rem;
            font-weight: 300;
            margin-bottom: 0.5rem;
            padding-bottom: 0.75rem;
            padding-top: 0.75rem;
            text-transform: uppercase;
            transform: translateY(-1rem);
            width: 215px;
        }
        h4 {
            color: #FFF;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        img {
            display: block;
            margin-bottom: 2rem;
        }
    }

    @media only screen and (min-width: $medium) {
        .tiles {
            justify-content: space-between;
            margin-left: -2rem;
            margin-right: -2rem;
            img {
                max-width: 100%;
            }
            img + p {
                margin-bottom: 4rem;
            }
        }
    }
    @media only screen and (min-width: $large) {
        .tiles {
            padding-bottom: 6rem;
            padding-top: 6rem;
            > * {
                margin-bottom: 0;
                img {
                    margin-left: -2.75rem;
                    max-width: none;
                    width: calc(100% + 6rem);
                }
            }
        }
    }
}

.wedding {
    .hero p:not(.subhead) {
        color: $brand-blue-light;
    }
}

.cell p,
div.image {
    img {
        border: 7px solid #fff;
        box-shadow: 0 0 20px rgba(0,0,0,.2);
    }
}

.component.html-embed {
    margin-bottom: 2rem;
    @media only screen and (min-width: $large) {
        margin-bottom: 4rem;
    }
}

.ticketing {
    background-color: #F5F5F5;
    margin-bottom: 2rem;
    padding: 3.5rem 1rem 1rem;
    position: relative;
    .eyebrow {
        background-color: #888;
        color: #444;
        font-size: 1.5rem;
        text-transform: uppercase;
        left: 0;
        padding: 0.5rem 1rem;
        position: absolute;
        top: 0;

        &.error {
            background-color: $error;
            color: #FFF;
            &::before {
                content: '';
            }
        }
    }
    h3 {
        color: $plain-text;
        font-size: 1.2rem;
        text-transform: uppercase;
    }
    p {
        // font-size: 0.9rem;
        margin-bottom: 1rem;
    }
    .wrapper {
        @include grid($align: flex-start);
        flex-wrap: wrap;
        > * {
            flex: 0 1 47.5%;
            margin-bottom: 1.5rem;
        }
    }
    ul {
        @include no-bullets;
        margin-bottom: 1.5rem;
    }
    p,
    .button {
        &:last-child {
            margin-bottom: 0;
        }
    }


    @media only screen and (min-width: $large) {
        .ticketing {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
            h3 {
                font-size: 1.3rem;
            }
        }
    }
}


aside.primary {
    background-color: #6083b2;
    background-image: url('/assets/images/membership-aside.png');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    color: #FFF;
    display: flex;
    flex-direction: column;
    font-size: 0.95rem;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    h2,
    h3,
    h4,
    h5 {
        color: #FFF;
        font-weight: 400;
        margin-bottom: 0.5em;
    }
    .module {
        padding: 1rem 0.5rem;
        p:last-of-type {
            margin-bottom: 0;
        }
    }
    .module + .module {
        border-top: 1px solid darken($brand-blue, 8%);
    }
    .module dl {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.15rem;
        margin-bottom: 0;
        dt,
        dd {
            flex: 1 0 70%;
            margin-bottom: 0.5rem;
            padding: 0;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        dt {
            font-weight: 400;
        }
        dd {
            flex-basis: 30%;
            font-weight: 700;
            padding-left: 1rem;
        }
    }
    .module {
        > div {
            margin-bottom: 0.75rem;
            a {
                color: #fff;
                font-weight: 400;
            }
            a:hover,
            a:focus {
                color: $brand-blue-light;
            }
            p {
                font-size: 0.9rem;
                color: #add0ff;
                font-weight: 600;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .copy {
        margin-bottom: 1.5rem;
    }
    h4 {
        display: block;
        font-size: 1.125rem;
        font-weight: 700;
        letter-spacing: 0.02em;
        padding-bottom: 0;
        text-transform: uppercase;
        a {
           color: #FFF;
           padding: 0;
        }
    }
    a {
        color: #f0f0f5;
    }
    a:hover {
        color: $brand-blue-light;
    }
    a[href$=".pdf"]::before {
        @include fontawesome("\f1c1");

        margin-right: 0.5em;
    }
    ul {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
        padding-left: 1.2rem;
    }
    li {
        margin-bottom: 0.5rem;
        padding-left: 0;
    }
    li a {
        display: block;
        font-weight: 400;
        padding: 0.4em 0;
    }
    nav.secondary {
        margin-bottom: 0;
        h4 {
            color: #FFF;
            font-size: 1.875rem;
            text-transform: none;
        }
        ul {
            @include no-bullets;
            flex-direction: column;
            margin-bottom: 0;
            text-align: left;
        }
        li {
            margin-bottom: 0;
        }
        > ul > li.active > a {
            font-weight: 700;
        }
        > ul > li.active {
            background-color: $brand-blue-medium;
            margin-bottom: 0.5rem;
            margin-left: -2.25rem;
            margin-right: -1.5rem;
            margin-top: 0.5rem;
            padding: 0.75rem 1.5rem 0.75rem 2.25rem;
            li {
                font-size: 0.9rem;
            }
        }

        > ul > li.active > ul > li.active a {
            background-color: darken($brand-blue-medium, 8%);
            font-weight: 700;
            margin-left: -0.5rem;
            padding: 0.25rem 0 0.25rem 0.5rem;
        }
        li li a:hover {
            color: $brand-blue-light;
        }
    }
    .cta {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/assets/images/bg-callout.jpg');
        margin-top: auto;
        position: relative;
        > * {
            position: relative;
        }
        &::before {
            background: linear-gradient(to bottom, rgba(143,157,175,1) 0%,rgba(143,157,175,1) 19%,rgba(143,157,175,0.46) 63%,rgba(0,0,0,0) 100%);            position: absolute;
            content: '';
            display: block;
            height: 80px;
            left: 0;
            top: 0;
            width: 100%;
        }
    }
    .review-membership {
        text-align: center;
        padding-top: 4rem;
        align-self: flex-start;
        margin-top: auto;

        h4 {
            text-transform: none;
        }

        .button {
            background: #fff;
            color: $plain-text;
            padding: .75rem 1.125rem;

            &:hover {
                background: $brand-blue-light;
            }
        }
    }
}

.author {
    div {
        display: inline-block;
        img {
            border: 1px solid #FFF;
            border-radius: 50%;
            box-shadow: none;
            margin-bottom: 0;
            margin-right: 0.15rem;
            max-width: 34px;
        }
    }
    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: none;
    }
    span {
        display: inline-block;
        position: relative;
    }
    a span::after {
        background-color: $link;
        bottom: 3px;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
    }
    a:hover span::after {
        background-color: $link-hover;
    }
}


footer.primary {
    background-color: #F0F0F0;
    color: $plain-text;
    padding: 3rem 0;
    text-align: center;
    .button {
        margin: 0 .75rem 2rem .75rem;
    }
    .branding {
        margin-top: 0;
        img {
            margin-bottom: 2rem;
            max-width: 230px;
            opacity: 0.7;
        }
        a {
            font-weight: 600;
        }
    }
    nav ul {
        flex-flow: column nowrap;
        margin-bottom: 1.25rem;
        text-align: center;
    }
    nav li {
        margin-bottom: 0.2rem;
    }
    nav li.section {
        font-weight: 600;
    }
    nav li.section a {
        color: $link;
        &:hover,
        &:focus {
            color: darken($link, 20%);
        }
    }
    nav a {
        color: $plain-text;
        display: inline-block;
        padding: 0.15rem 0;
        position: relative;
        &:hover,
        &:focus {
            color: #000;
        }
        &::before {
            background-color: #000;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 94%;
            transition: opacity 200ms ease, width 200ms ease;
            width: 0;
        }
    }
    nav a:hover,
    nav a:focus {
        &::before {
            opacity: 1;
            width: 100%;
        }
    }
    .social a {
        background-color: $link;
        border-radius: 50%;
        color: #FFF;
        display: block;
        font-size: 1.2rem;
        height: 36px;
        text-align: center;
        width: 36px;
        .fa-brands, .fa {
            line-height: 36px;
        }
    }
    .search form {
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        margin-bottom: 0;
        position: relative;

        input {
            border: 0;
            padding: 1rem 1.5rem;
        }

        button {
            background-color: transparent;
            margin-top: -1rem;
            padding: 0;
            position: absolute;
            right: 1rem;
            top: 50%;
        }
        button::before {
            @include fontawesome('\f002');
            color: $brand-green;
            font-size: 1.75rem;
            margin-top: -1.5rem;
        }
    }

    @media only screen and (min-width: $large) {
        background: linear-gradient(to right, #F0F0F0 calc(38% + .5rem), #f5f5f5 1%);
        padding: 6rem 0;
        /*.branding {
            text-align: left;
        }*/
        .wrapper {
            @include grid($align: flex-start);
            > div:first-child {
                flex: 0 0 32%;
            }
            > div:nth-child(2) {
                flex: 0 0 56%;
            }
        }
        nav ul {
            text-align: left;
        }
        .navs .wrapper nav {
            flex: 1 0 0;
            margin-left: 2rem;
        }
        .navs .wrapper nav:first-child {
            margin-left: 0;
        }
        .copyright {
            margin-top: 1rem;
            text-align: left;
        }
    }

    @media only screen and (min-width: $xl) {
        background: linear-gradient(to right, #F0F0F0 calc(50% - (1168px / 2) + (443.84px) ), #F5F5F5 0%);
    }
}

.social {
    @include no-bullets;

    margin-bottom: 1rem;
    li {
        display: inline-block;
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-right: 0.4rem;
    }

    .facebook a {
        color: $facebook;
    }
    .linkedin a {
        color: $linkedin;
    }
    .twitter a {
        color: $twitter;
    }
    a:hover {
        opacity: 0.7;
    }
}

i.fa.fa-wheelchair, i.fa.fa-wheelchair-move {
    color: #fff;
    background-color: $brand-blue;
    padding: 2px 2px 4px 4px;
    border-radius: 3px;
}

i.fa.fa-wheelchair-move {
    padding: 4px 4px 4px 4px;
}


/* = Typography = */

/*

200 Extralight
300 Light
400 Regular
500 Medium
600 Bold
800 Extrabold
900 Black
*/


h1,
h2,
h3,
h4,
h5 {
    color: $gray;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.8em;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    color: $brand-green;
    font-size: 1.5rem;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    font-size: 1rem;
}

strong {
    font-weight: 600;
}

em {
    font-style: italic;
}

blockquote {
    border-left: 1px solid $border;
    color: $meta-text;
    font-style: italic;
    margin-bottom: 1.5em;
    padding-left: 1rem;
}

.intro {
    p {
        font-size: 1.4rem;
        font-weight: 100;
        color: $plain-text;
        line-height: 1.5;
        margin-bottom: 3rem;
    }
}

.guide {
    color: $meta-text;
    font-size: 1.25rem;
}

.meta {
    color: $meta-text;
    font-weight: 400;
    margin-bottom: 1rem;
    &.divide {
        border-bottom: 1px solid $border;
        margin-bottom: 3rem;
        padding-bottom: 1rem;
    }
    a.button {
        @include button($background: $brand-blue);
        font-size: 0.9rem;
        font-weight: 500;
        padding: 0.5rem 1rem;
        vertical-align: inherit;
        margin-left: 10px;
    }
    a.button:hover {
        background-color: $brand-green;
    }
}

.handicap {
    color: #005c9b;
    display: block;
    font-weight: 400;
}

hr {
    border: 0;
    border-top: 1px solid $meta-text;
    display: block;
    height: 0;
    margin: 2em auto 3em;
    width: 100%;
}
a {
    color: $link;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:active {
        color: $link-hover;
    }
    &:focus {
        outline: dotted 1px;
    }

    &.disabled,
    &[disabled] {
        color: darken(#FFF, 20%);
        cursor: default;
        pointer-events: none;
    }

    // main & {
    //     text-decoration: underline;
    // }
}

a.button {
    @include button();
    margin-bottom: 0;
}
p.button a {
    @include button();
}
a.button.lead {
    background-color: transparent;
    color: #FFF;
    padding: 0;
    &::after {
        @include fontawesome('\f054');
        margin-left: 0.5rem;
    }
}

ol,
ul,
dl {
    margin-bottom: 2rem;
}

p {
    line-height: 1.7;
}

ol,
ul {
    padding-left: 2rem;
    li,
    dt,
    dd {
        margin-bottom: 0.5em;
    }
}

ul {
    list-style: disc;
}

dt {
    font-weight: 600;
    padding-left: 0.5em;
}

dd {
    padding-left: 2em;
}

pre {
    background-color: #4A4A4A;
    padding: 1em;
    white-space: pre-wrap;
    word-wrap: break-word;
}

code {
    color: #FBCE78;
    font-family: "Consolas", monospace;
    padding: 2px 4px;
}

.return {
    border-top: 2px solid $border;
    font-weight: 700;
    padding-top: 1.25rem;
    a {
        text-decoration: none;
    }
}

.event-type {
    display: inline-block;
    font-size: 0.815rem;
    font-weight: 400;
    position: relative;
    text-align: left;
    .wrapper {
        @include grid($align: center);
        flex-wrap: wrap;
        > .icon {
            flex: 0 0 auto;
            margin-right: 0.5rem;
        }
        > .copy {
            flex: 1 1 auto;
            letter-spacing: -0.02em;
        }
    }
    img {
        max-width: 20px;
    }
    .meta & {
        margin-left: 0.5rem;
        transform: translateY(-2px);
    }
}

.blurb {
    font-size: 1.2rem;
    font-style: italic;
    margin-top: 2rem;
}

.fb-like {
    clear: both;
    display: block !important;
}

/* = Forms = */

form.fluid {
    display: flex;
    padding: 1rem 0;
    > * {
        margin-bottom: 0;
    }
    .text {
        flex: 1 1 auto;
    }
    input {
        height: 3.25rem;
    }
}

button,
input,
optgroup,
select,
textarea {
    font: inherit;
    margin: 0;
}

.button,
.checkboxes,
.file,
.password,
.radios,
.reset,
.select,
.submit,
.text,
.textarea {
    margin-bottom: 2rem;
    aside.primary & {
        margin-bottom: 1rem;
    }
}

label {
    cursor: pointer;
    display: block;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.required {
    color: $error;
}

fieldset .help {
    color: $meta-text;
    display: block;
    font-size: 0.8rem;
    margin: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
    border: 1px solid darken($border, 10%);
    border-radius: 0;
    box-sizing: border-box;
    color: $plain-text;
    display: block;
    font-family: $font-stack;
    margin: 0;
    padding: 0.8rem 1rem;
    transition: box-shadow ease-in-out 0.3s;
    width: 100%;
    &:focus {
        box-shadow: inset 0 2px 0 0 $brand-blue;
    }
    aside.primary & {
        padding: 0.5rem 0.8rem;
    }
}

input[type="reset"],
input[type="search"],
input[type="text"],
input[type="submit"] {
    border-radius: 0;
    -webkit-appearance: none;
    appearance: none;
}

::-webkit-input-placeholder {
    color: #777;
    font-style: italic;
    font-weight: 300;
}

::ms-input-placeholder {
    color: #777;
    font-style: italic;
    font-weight: 300;
}

::-moz-placeholder {
    color: #777;
    font-style: italic;
    font-weight: 300;
}

::-placeholder {
    color: #777;
    font-style: italic;
    font-weight: 300;
}

.multiple select {
    padding-left: 5px;
    padding-right: 10px;
}

select {
    border: 1px solid $border;
    color: #333;
    font-size: 1.1rem;
    width: 100%;
}

textarea {
    height: 8em;
    overflow: auto;
    resize: vertical;
}

.radio,
.checkbox {
    font-weight: 400;
}

input[type="checkbox"],
input[type="radio"] {
    margin-right: 0.25rem;
    padding: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    @include button;
    border: none;
    cursor: pointer;
}


/* = Messaging = */

.success {
    @include messaging;

    background-color: $success;
    color: #FFF;
    &::before {
        content: "\f00c";
    }
    a {
        color: #FFF;
    }
    a:hover {
        color: darken(#FFF, 15%);
    }
}

.info {
    @include messaging;

    background-color: $info;
    &::before {
        content: "\f129";
    }
}

.error {
    @include messaging;

    background-color: $error;
    color: #FFF;
    &::before {
        content: "\f12a";
    }
    a {
        color: $info;
    }
    a:hover {
        color: darken($info, 15%);
    }
}


/* = Tables = */

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 2rem;
    width: 100%;
    caption {
        font-size: 0.9rem;
        margin-bottom: 0.75em;
    }
    td,
    th {
        padding: 0.65rem 1.15rem;
        text-align: left;
        vertical-align: top;
    }
    th {
        background-color: $brand-sand;
        color: #ffffe8;
        font-weight: 600;
    }
    td {
        border: 1px solid #f4f4f4;
    }
    tr:nth-child(even) td {
        background-color: #f8f8f8;
    }
}


/* = Calendar = */
.rail-calendar {
    width: 300px;
    overflow: hidden;
    position: relative;
    margin-bottom: 2em;
    .clndr-controls {
        background-color: #fff;
        border: 1px solid #f4f4f4;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .month {
            width: 70%;
            padding-top: 5px;
            padding-bottom: 5px;
            display: inline-block;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1px;
            margin: 0 auto;
        }
    }

    .days {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .days {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f4f4f4;
        border-left: 1px solid #f4f4f4;
        border-right: 1px solid #f4f4f4;
        &.name {
            .day {
                background-color: $brand-blue;
                color: #ffffff;
                font-weight: 400;
            }
        }
    }

    .day {
        background-color: #ffffff;
        border: 1px solid #f4f4f4;
        color: #212121;
        text-align: center;
        width: 14.28%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5em;
        &.adjacent-month {
            background-color: $lightgray;
            color: #999;
            &:hover {
                background-color: $lightgray;
                color: #999;
            }
        }
        &.today {
            position: relative;
            border-color: $brand-teal;
        }
        &.event {
            position: relative;
            cursor: pointer;
            &:after {
                position: absolute;
                font-family: FontAwesome;
                content: '\f111';
                color: $brand-blue;
                width: 5px;
                height: 5px;
                display: block;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 5px;
                font-size: .4em;
            }
            &:hover {
                background-color: $brand-blue;
                color: #fff;
            }
            &.adjacent-month {
                cursor: auto;
                &:after {
                    content: '';
                }
                &:hover {
                    background-color: $lightgray;
                    color: #999;
                }
            }
        }
    }
}

.day-information {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(100%);
    transition: transform .5s;
    background: #f4f4f4;
    header {
        background-color: $brand-blue;
        padding: 1em;
        color: #f4f4f4;
        margin-bottom: 0;
    }
    .details {
        padding: 1em;
        .event {
            margin: 0 0 1em 0;
            a {
                color: $brand-blue;
            }
        }
    }
    .close-information {
        background: none;
        border: none;
        position: absolute;
        top: -.75em;
        right: 0;
        padding: 1em;
    }
    .day-label {
        background: none;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.25em 1em;
        font-weight: 600;
        color: #fff;
    }
    &.active {
        transform: translateX(0);
        overflow: auto;
    }
}

/* = CMS = */

.shadow {
    img {
        box-shadow: 7px 7px 17px rgba(0,0,0,0.15);
    }
}
.embed {
    clear: both;
    margin: 0 0 1.4em;
    padding-top: 25px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.two-column img,
.two-col img {
    // border: 7px solid #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    margin-bottom: 1.75rem;
    p img {
        margin-bottom: 1.5rem;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

@media only screen and (min-width: $medium) {
    .image_align_right {
        float: right;
        margin-left: 2rem;
        max-width: 50%;
    }
    .image_align_left {
        float: left;
        margin-right: 2rem;
        max-width: 50%;
    }
}


/* =  Page Specific = */
body#home {
    .slides {
        background-color: #F0F0F0;
        overflow: hidden;
        position: relative;
        .slides-placeholder {
            background-color:#F0F0F0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }

    .slick-slide {
        text-align: center;
    }
    .slick-slide img,
    .slick-slide video {
        display: block;
        width: 100%;
    }
    .headline {
        bottom: 5rem;
        position: absolute;
        width: 100%;
        z-index: 999;

        > div {
            @include container;
        }
        h1 {
            color: #FFF;
            font-weight: 800;
            margin-bottom: 0;
            text-shadow: 0 0 20px rgba(0,0,0,0.3);
            text-transform: uppercase;
        }
    }
}

body#blog,
body.tags,
body.category {
    .listing {
        @include listing($direction: row, $imageWidth: 240px);
        > ul > li {
            @include transition;
            border-top: 4px solid $brand-green;
            box-shadow: 0 0 30px rgba(0,0,0,.1);
            margin-bottom: 3rem;
            padding: 2rem;

            &:hover {
                box-shadow: 0 0 30px rgba(0,0,0,.3);
            }
        }
        .wrapper {
            .photo {
                margin-bottom: 2rem;
                a {
                    cursor: pointer;
                }
            }
        }
    }
}


body.calendar {
    aside.primary {
        background-color: transparent;
        background-image: none;
        padding: 0;
    }
}

body.people {
    .hero .img {
        background-image: url('/assets/images/hero-marsh.jpg');
        background-position: 50% 50%;
    }
}

/* == Projects Listing == */
div.projects {
    @include tiles($gutter: 3rem, $columns: 2);
    > div,
    a {
        color: #FFF;
        text-decoration: none;
    }
    > div {
        background-color: rgba($brand-blue-dark, 0.8);
        margin-bottom: 5rem;
        margin-top: 2rem;
        position: relative;
        text-align: center;
        transition: background-color 500ms ease-in-out;

        .author {
            img {
                max-width: 90px;
                border: 3px solid #fff;
                display: none;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            span {
                color: #FFF;
                margin-left: 0.5rem;
            }
            > div:first-of-type {
                img {
                    display: block;
                }
                span {
                    margin-left: 0;
                }
            }

        }

        .img {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 110%;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: all 500ms ease-in-out;
            width: 100%;
            z-index: -1;
        }
        h3 {
            font-weight: 600;
            margin-bottom: .5rem;
        }
    }
    > div:hover {
        background-color: rgba($brand-blue-dark, 0.90);
        .img {
            background-size: 120%;
        }
    }
    a:hover {
        color: $brand-blue-light;
    }
    nav.pagination {
        background-color: transparent;
        flex-basis: 100%;
        padding: 0;
        a {
            color: $link;
        }
        a:hover {
            color: $link-hover;
        }
    }
    @media only screen and (min-width: $large) {
        > div {
            margin-bottom: 3rem;
        }
    }
}

.featured-projects{
    .fluid {
        margin: 1rem 0;
    }
}

/* == Related Blog Listing == */

.related-blogs {
    @extend .projects;
    align-items: flex-start;
    > div {
        overflow: hidden;
        .img {
            background-size: cover;
        }
    }


    > div:hover {
        .img {
            background-size: cover;
            transform: scale(1.5, 1.5);
        }
    }


}


/* == Search Listing == */
.search-listing {
    @include listing($direction: row);
}


/* = Media Queries = */

@media only screen and (min-width: $medium) {

    aside.primary {
        background-size: contain;
    }

    /* =  Page Specific = */
    body#home {
        .headline {
            top: 6rem;
            h1 {
                font-size: 4rem;
                line-height: 0.8;
                text-shadow: 0 0 80px rgba(0,0,0,0.4);
            }
        }
    }
}



@media only screen and (min-width: $large) {

    /* = Layout = */
    header.primary {
        .branding {
            flex-basis: 210px;
            margin-right: 2rem;
        }
    }

    nav {
        ul {
            text-align: left;
        }
    }

    nav.primary {
        margin-bottom: 0;
        width: 100%;
        > ul {
            align-items: center;
        }
        > ul > li {
            font-size: 1rem;
            font-weight: 600;
            padding-bottom: 0.75rem;
            position: relative;
        }
        > ul > li:first-child {
            margin-left: 0;
        }
        ul {
            text-align: left;
            li li {
                display: block;
                float: none;
                font-weight: 400;
            }
        }
        li.donate {
            margin-left: auto;
        }
    }

    .utilities {
        margin-bottom: 0.5rem;
        nav.utility {
            margin-bottom: 0.25rem;
            ul {
                text-align: right;
            }
            li a {
                padding: 0 0.75em;
            }
            li:last-child a {
                padding-right: 0;
            }
        }
    }

    .desktop {
        align-items: flex-end;
        display: flex;
        flex: 1 1 auto;
        flex-flow: column nowrap;
        justify-content: space-between;

        nav.primary {
            align-self: flex-end;
        }
    }

    button.menu-toggle {
        display: none;
    }

    .wrap {
        display: flex;
        justify-content: space-between;
        main {
            flex: 1 0 0;
        }
        aside.primary {
            flex: 0 0 280px;
            margin-bottom: 2rem;
            margin-left: 3rem;
            transform: translateY(-6.5rem);
        }
    }

    body.blog .wrap aside.primary,
    body.calendar .wrap aside.primary,
    body.event .wrap aside.primary,
    body.project .wrap aside.primary {
        transform: translateY(0);
    }


    .torso {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }

    aside.primary {
        margin-bottom: 0;
        nav ul {
            text-align: left;
        }
        .module {
            padding: 1.5rem 0.5rem;
        }
    }


    /* = Typography = */
    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.1rem;
    }

    h5 {
        font-size: 1rem;
    }


    /* =  Page Specific = */
    body#home {
        .headline {
            h1 {
                font-size: 7.5rem;
            }
        }
        section.calendar h2 {
            margin-bottom: 2.5rem;
        }
    }


    /* == Projects Listing == */
    div.projects {
        > * {
            h3 {
                font-size: 2rem;
            }
        }
    }

}

// Admin Bar Styling
.adminbar {
    header.primary {
        padding-top: 0;
    }
}

.admin_bar_links {
    margin-bottom: 0;
}

.search-error {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    color: $white;
    background: color.adjust($error, $lightness: 20%);

    /* Pseudos */
    &::before {
        content: '!';
        padding-left: 10px;
        padding-right: 15px;
        font-weight: bold;
    }

    /* Class Modifiers */
    &.-hide {
        display: none;
    }

    /* Contextual Modifiers */
    .search-drawer & {
        margin-top: 10px;
    }
}

/**
 * _components.scss contains solely the mixin definitions for components.
 *
 * + This file should not depend on styles.scss; we should be able to use
 *   include this file without including styles.scss.
 * + This file should not generate any CSS on it's own; it should only contain
 *   mixin and/or function definitions.
 */

/**
 * Media query breakpoints
 */

$medium: 669px;
$large: 1024px;

/**
 * BOARD
 *
 * The board component is a full width row with a background image.
 * It contains a screened copy block that is either aligned left or
 * right. You can see it used omn the homepage’s Featured Blog
 * and Wedding areas.
 *
 * Emmet: .board>.container>section>.copy>.wrapper>h2+a.button
 */
@mixin board() {
    background-color: $brand-blue-dark;
    background-position: center top;
    background-size: cover;
    padding: 2em 0;
    text-align: center;
    .wrapper {
        @include grid($justify: center);
        flex-direction: column;
    }
    h2 a:hover {
        color: $brand-green;
    }
    .copy {
        @include screen($background: rgba($navy, 0.7));
        padding: 2rem;
        * {
            color: #FFF;
        }
    }
    .subhead {
        color: #FFF;
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 0.25rem;
    }
    @media only screen and (min-width: $large) {
        padding: 6em 0;
        .wrapper {
            align-items: flex-start;
        }
        h2 {
            font-size: 1.85rem;
            margin-bottom: 0.75em;
        }
        .copy {
            margin-left: 0;
            margin-right: 0;
            text-align: left;
        }
        .push {
            @include grid($align: flex-end, $justify: center);
        }
        .pull {
            @include grid($align: flex-start, $justify: center);
        }
        .push,
        .pull {
            flex-direction: column;
            .copy {
                flex: 0 0 auto;
                width: 50%;
            }
        }
    }
}

.board {
    @include board();
}


/**
/**
 * BREADCRUMBS
 *
 * The breadcrumb mixin provides basic breadcrumb styling, complete with
 * fontawesome icons being used as separators between crumbs.
 *
 * Emmet: nav.breadcrumbs>ul>li*3>a
 */
@mixin breadcrumbs() {
    @include container;

    font-size: 0.9rem;
    position: static;
    text-align: left;
    > * {
        position: absolute;
        text-align: left;
        top: 1.5rem;
    }
    ul {
        @include no-bullets;
    }
    li {
        color: #FFF;
        display: inline-block;
        font-weight: 700;
        margin-right: 0.4rem;
    }
    li:last-child {
        margin-right: 0;
    }
    a {
        color: darken(#FFF, 8%);
        font-weight: 500;
        position: relative;
    }
    a::after {
        content: '/';
        display: inline-block;
        padding-left: 0.6rem;
    }
    a:hover {
        color: $brand-green;
        &::after {
            color: darken(#FFF, 8%);
        }
    }
}


/**
/**
 * CALENDAR
 *
 * Controls the calendar’s layout on both the homepage and /calendar view
 * fontawesome icons being used as separators between crumbs.
 *
 * Emmet: .calendar
 */
@mixin calendar() {
    h2,
    h3 {
        font-weight: 400;
    }

    .bar {
        background-color: $brand-blue;
        color: #FFF;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    div.primary {
        box-shadow: 0 6px 28px 0 rgba(0,0,0,0.2);
        margin-bottom: 2rem;
        position: relative;
        img {
            display: block;
        }
        .date,
        .accent {
            background-color: #4f5513;
            display: inline-block;
            font-weight: 600;
            left: -0.3rem;
            padding: 0.7rem 1rem;
            position: absolute;
            top: -0.3rem;
        }
        h3 {
            background-color: #849443;
            box-shadow: inset 0px -33px 33px -4px rgba(0,0,0,0.1);
            font-size: 1.1rem;
            margin-bottom: 0;
            padding: 1rem 1.75rem;
        }
        h3 a {
            color: #FFF;
            display: block;
            &:hover {
                color: darken($brand-green, 20%);
            }
        }
        .tease {
            background-color: $brand-green;
            padding: 1.75rem 1.75rem 2.5rem;
            p {
                margin-bottom: 1rem;
            }
            a.button.lead {
                color: #FFF;
                &:hover {
                    color: darken($brand-green, 20%);
                }
            }
        }
    }

    .secondary {
        font-weight: 400;
        ul {
            margin-bottom: 1.5rem;
        }
        ul li {
            border-bottom: 1px solid transparentize($border, 0.75);
            border-top: none;
            color: $brand-blue-light;
            padding: 1rem 0;
        }
        ul li a {
            color: #FFF;
            &:hover {
                color: darken($brand-blue, 20%);
            }
        }
        b {
            display: inline-block;
        }
        b + span {
            border-left: 1px solid #555;
            margin-left: 0.75rem;
            padding-left: 0.7rem;
        }
        h2 a {
            color: #FFF;
        }
        h2 a:hover {
            color: $brand-blue-light;
        }
        h3 {
            font-size: 1rem;
            line-height: 1.3;
            margin-bottom: 0;
        }
        a.button:hover {
            background-color: $brand-teal;
        }
        .wrapper {
            @include grid();
            > div:first-child {
                flex: 1 1 auto;
                margin-right: 2rem;
            }
            .button {
                flex: 0 1 auto;
                white-space: nowrap;
            }
        }
    }

    .summary {
        .date {
            display: block;
            font-weight: 500;
            margin-bottom: 1rem;
        }
        p {
            font-weight: 300;
        }
    }

    .listing .view {
        text-align: center;
        .wrapper {
            display: block;
            p {
                color: $brand-blue-light;
                font-size: 0.85rem;
                font-style: italic;
                font-weight: 300;
                margin-bottom: 1rem;
                text-align: center;
            }
            p .fa {
                margin-right: 0.2rem;
            }
        }
    }

    .aside {
        text-align: center;
        .date {
            background-color: $brand-blue;
            border-radius: 4px;
            color: #FFF;
            margin-bottom: 1rem;
            padding: 1.5rem 0;
        }
        .month {
            display: block;
            font-size: 0.9em;
            line-height: 1;
            text-transform: uppercase;
        }
        .day {
            font-size: 1.5rem;
            font-weight: 800;
            line-height: 1;
            padding: 0.2rem 0;
        }
    }

    .content {
        h3 {
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 1.25rem;
        }
        a.lead {
            color: $link;
        }
        a.lead:hover {
            color: $link-hover;
        }
    }

    .wrap {
        margin-top: 5rem;
    }

    .monthlys {
        border-top: 4px solid $border;
        padding-top: 2rem;
        ul {
            @include no-bullets;
            font-size: 1.25rem;
            font-weight: 400;
            justify-content: center;
            a {
                text-decoration: none;
            }
        }
    }

    @media only screen and (min-width: $medium) {
        .listing {
            .wrapper {
                @include grid($align: flex-start);
            }
            .aside {
                flex: 0 0 160px;
            }
            .content {
                flex: 1 1 0;
                margin-left: 2.5rem;
            }
            .view {
                text-align: left;
            }
            .view .wrapper {
                @include grid();
                flex-direction: row-reverse;
            }
            .view .wrapper p {
                flex: 1 1 auto;
                margin-bottom: 0;
                margin-left: 2rem;
                text-align: right;
            }
        }

        .monthlys {
            ul {
                font-size: 1.75rem;
            }
            li {
                margin-left: 1.5rem;
                margin-right: 1.5rem;
            }
        }

        .aside {
            .month {
                font-size: 1.5em;
            }
            .day {
                font-size: 2.75rem;
            }
        }
    }

    @media only screen and (min-width: $large) {
        .wrapper {
            @include grid($align: center);
        }
        div.primary {
            flex: 0 0 44%;
            margin-bottom: -2.25rem;
            margin-top: -2.25rem;
            h3 {
                font-size: 1.75rem;
                margin-top: -5rem;
            }
            h3,
            .tease {
                position: relative;
            }
        }

        .secondary {
            padding-bottom: 0;
            flex: 0 0 50%;
            ul li {
                border-width: 1px;
                padding: 1rem 0;
            }
            h3 {
                font-size: 1rem;
            }
        }

        .summary {
            .date {
                margin-bottom: 1.5rem;
            }
            p {
                font-size: 1.2rem;
            }
        }
    }
}

.cal {
    @include calendar();
}


/**
/**
 * CALL TO ACTION
 *
 * Generally at the bottom of pages. Uses a headline, copy and button to drive leads.
 * As of this writing, you can view a callout at the bottom of the /visit/helpful-info view.
 *
 * Emmet: .callout>h3+p
 */
@mixin callout() {
    background: url('/assets/images/bg-callout.jpg') no-repeat center top / cover;
    color: #FFF;
    font-weight: 500;
    margin-bottom: 2rem;
    .content {
        padding: 2rem;
    }
    h3 {
        background-color: $brand-sand;
        color: #FFF;
        display: inline-block;
        font-weight: 700;
        margin-bottom: 0;
        padding: 1rem;
    }
    p {
        text-shadow: 1px 1px 3px rgba(0,0,0,.4);
    }

    a {
        color: #FFF;
        position: relative;
    }
    a:hover {
        color: lighten($brand-green, 10%);
        &::after {
            background-color: lighten($brand-green, 10%);
        }
    }
    a.button {
        font-size: 1.125rem;

        &::after {
            display: none;
        }
    }
    @media only screen and (min-width: $medium) {
        margin-bottom: 4rem;
        .content {
            padding: 3rem 3rem 3.5rem 0;
            main.two-column & {
                padding-left: 3rem;
            }
        }
        h3 {
            margin-left: -1rem;
            margin-top: -1rem;
        }
        p {
            font-size: 1.25rem;
        }
    }
}

.callout {
    @include callout();
}



/**
 * CAROUSEL
 *
 * CAROUSEL uses Slick to create a carousel. It defaults to a rather
 * basic, one-slide-at-a-time implementation with dots, but slick supports a
 * good deal of configuration including showing multiple slides, breakpoints,
 * syncing between slides, and plenty of javascript events and methods for
 * integration.
 *
 * The implementation on Boilerplate is using a CDN to provide
 *
 * + slick.css
 * + slick-theme.css
 * + slick.min.js
 *
 * See http://kenwheeler.github.io/slick/ for more information.
 *
 * The carousel() mixin should be included on a parent element that contains
 * one div per slide. No additional classes are necessary – the slick classes
 * referenced are added dynamically by slick.
 *
 * <div class="carousel">
 *     <div>
 *         <h2>A slide!</h2>
 *         <p>Some text.</p>
 *     </div>
 * </div>
 *
 * Emmet: .carousel>div*3>h2+p
 *
 * While there's more information in slick's documentation, .slick-slide wraps
 * slides while .slick-next and .slick-prev are used for the next/previous
 * arrows.
 */
@mixin carousel() {
    .slick-slide {
        color: #FFF;
        height: auto;
    }
    .slick-prev,
    .slick-next {
        z-index: 1;
    }
    .slick-prev {
        left: 2rem;
    }
    .slick-next {
        right: 2rem;
    }
}

.carousel {
    @include carousel;
}


/**
 * COLUMNS
 *
 * The COLUMNS mixin makes it so that immmediate child elements appear as
 * columns. It is responsive, allowing you to specify the media query at which
 * to switch the layout from stacking to columns.
 *
 * Additionally, it assumes the use of <hr> elements to create visual gutters
 * that are only visible while displayed as columns.
 *
 * <div class="columns">
 *     <div>
 *         <h2>A column</h2>
 *     </div>
 *     <hr />
 *     <div>
 *         <h2>Another column</h2>
 *     </div>
 * </div>
 *
 * Emmet: .columns>div+(hr+div)*2
 */
@mixin columns() {
    > hr {
        border: 0;
        display: none;
    }
    @media only screen and (min-width: $medium) {
        & {
            display: table;
            table-layout: fixed;
            width: 100%;
            > * {
                display: table-cell;
                vertical-align: top;
            }
            > hr {
                display: table-cell;
                width: 1rem;
            }
        }
    }
}

.columns {
    @include columns;
}


/**
 * DOSSIER
 *
 * The DOSSIER mixin displays an individual’s biography and photograph.
 *
 * Emmet: .dossier
 */
@mixin dossier() {
    .wrap {
        main {
            h1 {
                position: relative;
                margin-bottom: 3rem;
                padding-bottom: 2.5rem;
                &::after {
                    background-color: $brand-blue-dark;
                    bottom: 0;
                    content: '';
                    display: block;
                    height: 5px;
                    left: 0;
                    position: absolute;
                    width: 60px;
                }
                b {
                    color: $meta-text;
                    display: block;
                    font-size: 0.9rem;
                    font-weight: 300;
                    letter-spacing: 0.04em;
                    margin-top: 1rem;
                    text-transform: uppercase;
                }
            }
            h2 {
                font-size: 1.1rem;
                margin-bottom: 0;
            }
        }
        aside.primary {
            img {
                border-radius: 50%;
                border: 5px solid #fff;
            }
            .photo {
                margin-bottom: 2rem;
                max-width: 320px;
                text-align: center;
                margin-top: 2rem;
            }
        }
        @media only screen and (min-width: $large) {

            aside.primary {
                flex: 0 0 340px;
                margin-left: 6rem;
                text-align: left;
                .photo {
                    max-width: 80%;
                    text-align: left;
                    margin-top: -4rem;

                margin-left: auto;
                margin-right: auto;
                }
            }
        }
    }
}

.dossier {
    @include dossier();
}



/**
 * DROPDOWN
 *
 * The DROPDOWN mixin allows for dropdown menus. By default, a single
 * column dropdown is used. It is positioned to its parent.
 *
 * Emmet: nav.primary>ul>li>a{link name}+.dropdown>.menu>ul>li>(a{link name})*3
 */
@mixin dropdown($background: #2A2A2A, $color: $link) {
    @media only screen and (max-width: ($large - 1px)) {
        .submenu,
        i.fa {
            display: none;
        }
    }
    @media only screen and (min-width: $large) {
        i.fa {
            display: inline-block;
        }
        .submenu {
            background-color: $background;
            color: #FFF;
            display: block;
            font-size: 1rem;
            left: 0;
            min-width: 260px;
            opacity: 0;
            overflow-y: visible;
            padding: 2rem 0;
            position: absolute;
            top: 100%;
            transition: opacity 300ms;
            visibility: hidden;
            z-index: 1;
            ul {
                display: block;
            }
            .menu {
                > * {
                    padding: 0 1rem;
                }
                li {
                    margin-bottom: 0.4em;
                }
                a {
                    color: $color;
                }
                a:hover,
                a:focus {
                    color: darken($brand-blue-dark, 20%);
                }
            }
        }
        .submenu.open {
            opacity: 1;
            visibility: visible;
        }
    }
}


/**
 * DROPDOWN (mega)
 * The DROPDOWN (mega) mixin allows for full-width mega dropdown
 * menus. Call this mixin on a list item. Note that the simple
 * dropdown component is a dependency of this mega dropdown.
 * The mega dropdown is positioned to header.primary instead
 * of its parent list item.
 *
 * Emmet: nav.primary>ul>li>a{link name}+.dropdown>.menu(div*3)
 */
@mixin dropdown-mega() {
    position: static;
    @media only screen and (min-width: $large) {
        .dropdown {
            left: 0;
            top: 100%;
            width: 100%;
            .menu {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                margin-left: auto;
                margin-right: auto;
                max-width: 1168px;
                > * {
                    border-left: 1px solid $border;
                    flex-basis: 0;
                    flex-grow: 1;
                    padding: 0 3rem;
                }
                > *:first-child {
                    border-left: none;
                    padding-left: 0;
                }
                > *:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}


/**
 * FEATURES house three features in one row. Powered by flexbox,
 * this allows for a bottom alignment of each feature’s anchor. Use the
 * <code>$gutter</code> variable to pass you preferred gutter width.
 *
 * Emmet: .features>(div>(.icon>img)+h4+p+a)*3
 */
@mixin features($gutter: 3rem) {
    > * {
        background-color: $brand-blue;
        color: #FFF;
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
        padding: 1.5rem;
        text-align: center;
        h3 {
            font-size: 1.15rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }
    }
    @media only screen and (min-width: $large) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        > * {
            flex: 1 0 0;
            margin-left: $gutter;
            margin-right: 0;
            h3 {
                font-size: 1.25rem;
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.features {
    @include features($gutter: 3rem);
}


/**
 * FILTER
 *
 *
 * Holds a form elements in a columned layout. Used on the /projects view.
 *
 */
@mixin filter() {
    background-color: #f4f4f4;
    margin-bottom: 2rem;
    padding: 1.5rem;

    .select,
    .text {
        margin-bottom: 0;
    }

    select {
        background-color: #FFF;
    }

    @media only screen and (min-width: $medium) {
        margin-bottom: 3rem;
        .wrapper {
            @include grid($align: flex-start);
            > * {
                flex: 0 1 48%;
            }
        }
    }
}

.filter {
    @include filter();
}


/**
 * IMAGECAPTION
 *
 * Emmet:
 */
@mixin imagecaption() {
    margin-bottom: 2rem;
    .wrapper {
        .image {
            margin-bottom: 2rem;
        }
        .caption {
            color: #757575;
            font-size: 1rem;
        }
    }

    @media only screen and (min-width: $medium) {
        margin-bottom: 4rem;
        .wrapper {
            @include grid($align: flex-start, $justify: space-between);
            .image {
                flex: 0 1 auto;
            }
            .caption {
                border-top: 3px solid;
                flex: 0 0 230px;
                margin-left: 2rem;
                padding-top: 1rem;
            }
        }
    }
}

.imagecaption {
    @include imagecaption();
}


/**
 * LAYER
 *
 * Layer is used on top-level views. Houses a background-image, copy text block, button.
 *
 * Emmet: .layer>(.media>img)+(.content>h3+p+a.button)
 *
 */
@mixin layer() {
    color: #FFF;
    font-size: 0.95rem;
    margin-bottom: 0;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    text-align: center;
    .wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        .media div {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        }
    }
    h3 {
        color: #FFF;
    }
    p {
        margin-bottom: 2rem;

        a {
            color: #fff;
            font-weight: 600;
            text-decoration: underline;
        }
    }
    .media {
        flex: 0 0 190px;
        max-width: 240px;
    }
    .content {
        background-color: rgba(#25333e, 0.75);
        padding: 1rem 1.25rem;

        .button:hover {
            background-color: $brand-blue-light;
            color: $plain-text;
        }
    }
    &:nth-child(odd) {
        background-image: url('/assets/images/bg-layer.jpg');
        background-color: $brand-blue;
        background-position: center top;
        background-size: cover;
    }
    @media only screen and (min-width: $medium) {
        text-align: left;
        .wrapper {
            align-items: center;
            flex-direction: row;
        }
        .media {
            flex: 0 0 51%;
            max-width: none;
        }
        .content {
            color: #FFF;
            flex: 1 1 auto;
            margin-left: -8%;
        }
        .button {
            font-size: 1.2rem;
            padding: 1.2rem 1.5rem;
        }
        &:nth-child(odd) {
            .wrapper {
                flex-direction: row-reverse;
            }
            .content {
                z-index: 1000;
                margin-left: 0;
                margin-right: -8%;
            }
        }
    }
    @media only screen and (min-width: $large) {
        padding-bottom: 4rem;
        padding-top: 4rem;
        .content {
            padding: 2rem 2.5rem;
        }
    }


    .two-column & {
        background: none;

        .wrapper {
            background-color: $brand-blue;
            justify-content: space-between;
            align-items: stretch;
        }

        .media {
            transform: translate(-50%, -1rem);
            position: relative;
            left: 50%;
            margin-bottom: 2rem;
            box-shadow: 0 0 20px rgba(0,0,0,.3);

            div {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                top: -1rem;
                bottom: -1rem;
                overflow: hidden;
                height: auto;
                width: 100%;
            }
        }

        .content {
            background-color: transparent;
        }

        &:nth-child(odd) {
            background: none;

            .wrapper {
                background-color: $brand-green;
            }
            .media {
                transform: translate(-50%, -1rem);
            }
        }
        @media only screen and (min-width: $medium) {
            .media {
                transform: translate(1rem);
                flex: 0 0 40%;
                margin-bottom: 0;
                left: auto;
            }

            .content {
                flex: 0 0 55%;
            }

            &:nth-child(odd) {
                .media {
                    transform: translate(-1rem);
                }
            }
        }
    }
}

.layer {
    @include layer();
}


/**
 * LISTING shows a listing of entries. It includes an optional search form with filters.
 * It is used on the calendar, serch listing, blog listing views.
 *
 * Remove the search form elements for a simple list.
 *
 * Emmet: .listing>(form>.search>(.text>label+input^.submit>label+input[type="submit"]))+p.guide{Showing 10 of 20}+ul>li>a>h2{Listing headline}
 *
 */
@mixin listing($direction: column, $imageWidth: 200px) {
    margin-bottom: 2rem;
    form {
        .search {
            display: flex;
            height: 54px;
            margin-bottom: 3rem;
            label {
                @include visuallyhidden;
            }
            .text {
                flex-grow: 1;
            }
            .submit {
                flex-basis: 120px;
                flex-shrink: 0;
            }
            .submit input {
                height: 100%;
                width: 100%;
            }
        }
        .filters {
            margin-bottom: 2rem;
            label {
                display: inline;
            }
            span {
                background-color: darken($link, 20%);
                border-radius: 0.5rem;
                color: #fff;
                display: inline-block;
                margin: 0 0.5rem 0.5rem 0;
                padding: 0.5rem 1rem;
            }
            input[type="checkbox"]:checked + span {
                background-color: $link;
            }
            input[type="checkbox"] {
                display: none;
            }
        }
    }
    a {
        text-decoration: none;
    }
    > ul {
        list-style: none;
        padding: 0;
        margin: 0;
        > li {
            border-top: 4px solid #EEE;
            margin-bottom: 0;
            padding: 2rem 0;
            .meta {
                display: block;
            }
            h2 + .meta {
                margin-top: -1em;
            }
            p:last-of-type {
                margin-bottom: 0;
            }
            .image {
                margin-bottom: 2rem;
            }
        }
    }
    @media only screen and (min-width: $medium) {
        > ul > li {
            border-width: 10px;
            display: flex;
            flex-direction: $direction;
            padding: 3.5rem 0;
            .image {
                flex: 0 0 $imageWidth;
                margin-bottom: 0;
                margin-right: 2rem;
            }
            .content {
                flex: 1 1 auto;
                overflow: hidden;
            }
        }
    }
}

.listing {
    @include listing();
}


/**
 * The LOGIN mixin is a basic implementation of a login module that includes a
 * username input, password input, submit button, forgot password link, and
 * remember me checkbox
 *
 * Emmet: .login>form>(div.text>label{Username}+input)+(div.password>label{Password}+input[type="password"])+div.actions>(.submit>button{Login})+(label>input[type="hidden"]+input[type="checkbox"])+a{Forgot Your Password?}
 */
@mixin login {
    background: #EEE;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    padding: 3rem;
    input {
        &:focus {
            box-shadow: 0 0 5px rgba(114, 164, 0, 0.7);
        }
    }
    .submit {
        text-align: center;
    }
    .actions {
        text-align: center;

    }
    @media only screen and (min-width: $medium) {
        .actions {
            align-items: center;
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            > * {
                margin: 0;
                padding: 0;
            }
            label {
                margin-left: 1em;
            }
            a {
                display: block;
                margin-left: auto;
                text-align: right;
            }
        }
    }
}

.login {
    @include login;
}


/**
 * MOBILE NAVIGATION
 *
 * Emmet
 */

@mixin mobileNavigation() {
    background-color: $charcoal;
    bottom: 0;
    display: none;
    height: 100%;
    opacity: 0;
    overflow-y: auto;
    padding: 1rem;
    position: fixed;
    top: 0;
    transition: transform .25s;
    width: 80%;
    z-index: 9000;
    &.left {
        display: block;
        left: 0;
        right: auto;
        transform: translateX(-100%);
    }
    &.right {
        display: block;
        left: auto;
        right: 0;
        transform: translateX(100%);
    }
    &.left.open, &.right.open {
        transform: translateX(0);
        transition: transform .25s;
    }
    &.init {
        display: block;
        opacity: 1;
    }
    nav.utility {
        ul li a {
            padding: 0.75rem 0;
        }
    }
    ul {
        display: block;
        padding-left: 0;
    }
    ul li {
        align-items: center;
        border-bottom: 1px solid lighten($charcoal, 10%);
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-bottom: 0;
        text-align: left;
        a {
            color: #fff;
            display: block;
            padding: 1rem 0;
        }
        i.fa {
            display: inline-block;
        }

    }
    @media only screen and (min-width: $large) {
        &.open.left {
            transform: translateX(-100%);
        }
        &.open.right {
            transform: translateX(100%);
        }
    }
}

.header-content > .submenu,
.mobile {
    @include mobileNavigation();
}


/**
 * PAGINATION
 *
 * Emmet: nav.pagination>ul>(li.previous>a{Previous})+(li*2>a)+(li.active>a)+(li*2>a)+li.next>a{Next}
 */
@mixin pagination {
    display: flex;
    justify-content: center;
    ul {
        border: 1px solid $border;
        border-radius: 2px;
        display: flex;
        justify-content: center;
    }
    li {
        margin-bottom: 0;
    }
    a {
        color: $link;
        padding: 0.75rem 1.25rem;
        position: relative;
        font-size: 1.2rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    li:not(:last-child) a {
        border-right: 1px solid $border;
    }
    li.active a {
        background-color: $brand-blue;
        color: #fff;
        cursor: default;
        pointer-events: none;
        &::before,
        &::after {
            background-color: $brand-blue;
        }
        &:hover {
            color: #fff;
            background-color: $link-hover;
            &::before,
            &::after {
                background-color: $link-hover;
            }
        }
    }
    li.disabled a {
        color: $border;
        cursor: default;
        pointer-events: none;
    }
    li.previous a {
        &::before {
            @include fontawesome("\f100");
        }
    }
    li.next a {
        &::before {
            @include fontawesome("\f101");
        }
    }
    li:hover:not(.active):not(.disabled) {
        a {
            background-color: $link;
            color: #FFF;

            &::before {
                background-color: $link;
                color: #FFF;
            }
        }
    }
}

nav.pagination {
    @include pagination();
}


/**
 * PROFILES
 *
 * Profiles include a three-up of staff to be included on any page.
 *
 */
@mixin profiles() {
    text-align: center;
    .wrapper > div {
        margin-bottom: 2rem;
    }

    h3 {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    p {
        color: $plain-text;
        font-size: 0.85rem;
        margin-bottom: 0;
    }

    a {
        display: block;
        text-decoration: none;

        h3 {
            color: $link;
        }
    }

    a:hover {
        h3 {
            color: $link-hover;
        }
    }

    img {
        border-radius: 50%;
        margin-bottom: 1.5rem;
        max-width: 200px;
    }
    @media only screen and (min-width: $medium) {
        .wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .wrapper > div {
            flex: 0 1 230px;
        }
        img {
            max-width: 100%;
        }
    }
    @media only screen and (min-width: $large) {
        margin-bottom: 4rem;
    }
}

.profiles {
    @include profiles();
}


/**
 * Split
 *
 * Split provides a two-column structure with light styling to an h3.
 *
 * Emmet: .split>.container>.wrapper>.cell*2
 */
@mixin split() {
    h3 b {
        display: block;
        font-size: 1.25rem;
        font-weight: 200;
        margin-top: 0.4rem;
    }
    .cell {
        margin-bottom: 2rem;
    }
    @media only screen and (min-width: $medium) {
        .wrapper {
             @include grid($align: top);
        }
        .cell {
            flex: 0 0 45%;
            margin-bottom: 0;
        }
    }
}

.split {
    @include split();
}


/**
 * STICKY HEADER
 *
 * The STICKY-HEADER mixin works with js (sticky-header.plugin.js) to hide the
 * header as you scroll down, and show it as you scroll back up. This is
 * responsive as the "top" value is dynamically set in the
 * js based on the height of the header. Top padding is also added
 * to the body dynamically via js so that the header doesn't overlap any
 * content.
 *
 * Emmet: header.primary
 *
 **/
@mixin sticky-header() {
    position: fixed;
    top: 0;
    transition: top 0.16s cubic-bezier(0, .40, .70, 1);
    width: 100%;
}

header.primary.sticky {
    @include sticky-header();
}


/**
 * SUBNAV
 *
 * Horizontal nagigation pattern that links to child pages. As of this writing,
 * can be seen on the /about-us view.
 *
 * Emmet: .subnav>.container>nav>ul>li>a
 */
@mixin subnav() {
    font-weight: 600;
    padding-bottom: 2rem;
    position: relative;
    text-align: center;
    &::after {
        background-color: lighten($brand-teal, 25%);
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        margin-left: -120px;
        position: absolute;
        width: 240px;
    }
    h2 {
        color: #999;
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 0.06em;
        margin-bottom: 0.75rem;
        text-transform: uppercase;
    }
    nav {
        margin-bottom: 0;
    }
    ul {
        @include no-bullets;
        flex-wrap: wrap;
        justify-content: center;
    }
    li {
        margin-bottom: 0.75rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-size: .9375rem;
    }
    a {
        text-decoration: none;
    }
    a:hover {
        color: darken($link-hover, 10%);
    }
    @media only screen and (min-width: $large) {
        ul li {
            margin-bottom: 0;
        }
    }
}

.subnav {
    @include subnav();
}



/**
* TESTIMONIAL
*
* The TESTIMONIAL mixin is a basic implementation of a testimonial design.
* Emmet: div.testimonial>div.container>blockquote+p
* To use the TESTIMONIAL WITH ASSET component, you simply need to add additional html markup, see emmet below.
* Emmet: div.testimonial.asset>div.container>(div>img)+blockquote+p
*/

@mixin testimonial($gutterWidth: 4rem) {
    background-color: $charcoal;
    margin-bottom: 4rem;
    padding: 4rem 0 4rem;
    text-align: center;
    .asset {
        margin-bottom: 2rem;
        img {
            max-width: 280px;
        }
    }
    blockquote {
        border: none;
        color: #fff;
        font-size: 1.125rem;
        margin-bottom: 1rem;
        padding: 0 2.5rem;
        position: relative;
        &::before,
        &::after {
            color: $meta-text;
            font-family: Georgia, serif;
            font-size: 3.375rem;
            position: absolute;
        }
        &::before {
            content: "“";
            left: 0;
            line-height: 1;
            top: 0;
        }
        &::after {
            content: "”";
            line-height: 0;
            right: 0;
            bottom: 0;
        }
        ~ p {
            color: $meta-text;
            font-size: 0.875rem;
            margin-bottom: 0;
            padding: 0 2.5rem;
        }
    }
    @media only screen and (min-width: $large) {
        text-align: left;
        .container > div {
            display: flex;
            justify-content: space-between;
        }
        .asset {
            flex-basis: 33%;
            flex-shrink: 0;
            margin-bottom: 0;
            margin-right: $gutterWidth;
            img {
                max-width: 100%;
            }
        }
        blockquote {
            font-size: 1.5rem;
            padding: 0 6rem;
            &::before,
            &::after {
                font-size: 7rem;
            }
            ~ p {
                font-size: 1rem;
                padding: 0 6rem;
            }
        }
    }
}

.testimonial {
    @include testimonial;
}


/**
* TAGS
*
* Tags are used on blog detail views.
*
*/

@mixin tags() {
    @include grouping;

    clear: both;
    div {
        border: none;
        float: left;
        padding: 0;
    }
    a {
        background-color: $link;
        border-radius: 3px 0 0 3px;
        color: #FFF;
        display: inline-block;
        font-size: 0.85rem;
        height: 26px;
        letter-spacing: 0.02em;
        line-height: 26px;
        padding: 0 20px 0 10px;
        position: relative;
        margin: 0 10px 10px 0;
        text-decoration: none;
        transition: background-color 0.2s;
        &::after {
            background: #fff;
            border-bottom: 13px solid transparent;
            border-left: 10px solid $link;
            border-top: 13px solid transparent;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            transition: border-left-color 0.2s;
        }
    }
    a:hover {
        background-color: $link-hover;
        color: #FFF;
        transition: background-color 0.2s;
        &::after {
            border-left-color: $link-hover;
            transition: border-left-color 0.2s;
        }
    }
}

div.tags {
    @include tags();
}


/**
 *  TILES
 *
 *  Tiles helps you create an equal-width column grid layout without the use of row wrappers.
 *  Tiles is similar to the Features component, expect that Tiles’s items can wrap.
 *  Powered by flexbox, you can pass in a $gutter width and $column amount. Note that the
 *  specified $column amount only is respected at the large breakpoint. Tiles will automatically
 *  two-up your items at the medium breakpoint, and stack them at the small breakpoint.
 *
 *
 * Emmet: .tiles>.tiles
 *
 **/

@mixin tiles($gutter: 2rem, $columns: 4) {
    > * {
        background-color: #EEE;
        margin-bottom: $gutter;
        padding: 2rem;
        text-align: left;
        *:last-child {
            margin-bottom: 0;
        }
    }
    @media only screen and (min-width: $medium) {
        display: flex;
        flex-flow: row wrap;
        margin-left: -1rem;
        margin-right: -1rem;
        > * {
            flex-basis: calc(50% - #{$gutter});
            flex-shrink: 0;
            margin-left: $gutter / 2;
            margin-right: $gutter / 2;
            padding: 3rem 4rem;
        }
    }
    @media only screen and (min-width: $large) {
        > * {
            flex-basis: calc((100% / #{$columns}) - (#{$gutter}));
        }
    }
}

.tiles {
    @include tiles();
}

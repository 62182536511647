/**
 * _mixins.scss
 *
 * + The Mixins import includes 'helper' mixins. These are not robust
 * enough to be components.
 */


@mixin button($background: $brand-green, $color: #FFFFFF) {
    background-color: $background;
    color: $color;
    display: inline-block;
    font-weight: 600;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    vertical-align: top;

    &:hover {
        background-color: $brand-blue;
        color: #FFF;
    }
    &:focus {
        outline: 1px dotted black;
    }
    &.disabled,
    &[disabled] {
        background-color: lighten($charcoal, 45%);
        color: darken(#FFF, 20%);
        pointer-events: none;
    }

    &.secondary {
        padding: 0.75rem 2.5rem;
    }
    &.reverse {
        background-color: #FFF;
        color: $plain-text;
        &:hover {
            background-color: $brand-green;
            color: #FFF;
        }
    }
}

@mixin container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
}

@mixin grid($align: center, $justify: space-between) {
    align-items: $align;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: $justify;
    > * {
        flex: 1 0 auto;
    }
}

@mixin grouping {
    &::before,
    &::after {
        content: "";
        display: table;
    }
    &::after {
        clear: both;
    }
}

@mixin fontawesome($character: "") {
    font: normal normal normal 1em unquote("/") 1 FontAwesome;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
    text-rendering: auto;
    text-transform: none;

    @if $character != "" {
        content: $character;
    } @else {
        display: inline-block;
    }
}

@mixin messaging {
    margin-bottom: 2rem;
    padding: 1em 3em;
    position: relative;
    p:last-of-type,
    ul:last-of-type,
    ul li:last-of-type {
        margin-bottom: 0;
    }
    ul {
        margin-top: 0.3rem;
    }
    a {
        text-decoration: underline;
    }
    &:empty {
        display: none;
    }
    &::before {
        @include fontawesome;
        content: "";
        display: block;
        font-size: 20px;
        height: 23px;
        left: 1em;
        position: absolute;
        top: 1em;
        width: 23px;
    }
}

@mixin no-bullets {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li::before {
        display: none;
    }
}

@mixin screen($alpha: 0.6, $background: $brand-blue) {
    position: relative;
    @if $background == transparent {
        background-color: transparent;
    } @else {
        background-color: rgba($background, $alpha);
    }

    .img {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}


@mixin transition($properties: all, $duration: 250ms, $easing: ease-in-out) {
    @if length($properties) > 1 {
        -webkit-transition-property: $properties;
        transition-property: $properties;
    } @else {
        -webkit-transition: $properties $duration $easing;
        transition: $properties $duration $easing;
    }
}

@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

